import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend( duration );
dayjs.extend( relativeTime );
dayjs.extend( utc );
dayjs.extend( customParseFormat );

export type CountdownDetail = {
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export function parseTime ( time: string ) {
  return dayjs( time, "HH:mm:ss" );
}

export function timeAgo ( date: Date ) {
  const now = dayjs();
  const givenDate = dayjs.utc( date );

  if ( now.diff( givenDate, "day" ) < 1 ) {
    return givenDate.fromNow();
  }
}

export function daysUntil ( targetDate: Date ) {
  const now = dayjs().utc().startOf( "day" );
  const target = dayjs( targetDate ).utc().startOf( "day" );
  return target.diff( now, "day" );
}

export function detailedCountdown ( targetDate: Date, sourceDate?: Date ): CountdownDetail {
  const now = ( sourceDate ? dayjs( sourceDate ).utc() : dayjs().utc() );
  const target = dayjs( targetDate ).utc();
  const diff = target.diff( now );

  const countdown = dayjs.duration( diff );

  return {
    months: countdown.months() + ( countdown.years() * 12 ),
    days: countdown.days(),
    hours: countdown.hours(),
    minutes: countdown.minutes(),
    seconds: countdown.seconds(),
  };
}
