import type { ComputedRef } from "vue";
import { titleCase } from "title-case";
import dayjs from "dayjs";
import { useUserLanguage } from "./useUserLanguage";
import { parseTime } from "~/utils/dates";

export function useLocalization () {
  const userLanguage = useUserLanguage();

  function getOrdinalSuffix ( day: number ): string {
    if ( day > 3 && day < 21 ) return "th";
    switch ( day % 10 ) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  function formatHeading ( heading: string ): string {
    if ( userLanguage.value === "en-GB" ) {
      return heading;
    }

    return titleCase( heading );
  }

  function formatTimeString ( time: string ) {
    if ( userLanguage.value === "en-GB" ) {
      return parseTime( time ).format( "HH:mm" );
    }
    else {
      return parseTime( time ).format( "hh:mm A" );
    }
  }

  function formatTime ( date: Date ): string {
    const use24HourFormat = userLanguage.value === "en-GB";
    const formatString = use24HourFormat ? "HH:mm" : "hh:mm A";
    return dayjs( date ).format( formatString );
  }

  function formatDate ( date: Date ): ComputedRef<string> {
    return computed( () => {
      const day = date.getDate();
      const dayWithSuffix = day + getOrdinalSuffix( day );

      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const formatter = new Intl.DateTimeFormat( userLanguage.value, options );
      const parts = formatter.formatToParts( date );

      if ( userLanguage.value === "en-GB" ) {
        return `${parts.find( p => p.type === "weekday" )?.value}, ${dayWithSuffix} ${parts.find( p => p.type
          === "month" )?.value} ${parts.find( p => p.type === "year" )?.value}`;
      }
      else {
        return `${parts.find( p => p.type === "weekday" )?.value}, ${parts.find( p => p.type
          === "month" )?.value} ${dayWithSuffix} ${parts.find( p => p.type === "year" )?.value}`;
      }
    } );
  }

  function getCurrentCurrency () {
    return userLanguage.value === "en-GB" ? "GBP" : "USD";
  }

  function formatCurrency ( amount: number ) {
    return new Intl.NumberFormat( userLanguage.value, {
      style: "currency",
      currency: getCurrentCurrency(),
      minimumFractionDigits: ( amount % 1 === 0 ) ? 0 : 2,
    } ).format( amount );
  }

  function formatTimeAgo ( date: Date ) {
    const result = timeAgo( date );
    if ( result ) {
      return result;
    }
    else {
      return dayjs.utc( date ).format( "YYYY-MM-DD" );
    }
  }

  return {
    getCurrentCurrency,
    formatCurrency,
    formatHeading,
    formatDate,
    formatTime,
    formatTimeAgo,
    formatTimeString,
  };
}
